import classNames from 'classnames';

const RichText = ({ text, className = '' }) => {
    if (!text) {
        return null;
    }

    return text.map((value, index) => {
        const {
            annotations: {
                bold,
                code,
                color,
                italic,
                strikethrough,
                underline,
            },
            text,
        } = value;

        let backgroundColorName = null;
        let isBackgroundColor = false;

        // if color end with _background, set background color
        if (color?.endsWith('_background')) {
            //  parse color name
            backgroundColorName = color.split('_')[0];
            isBackgroundColor = true;
        }
        return (
            <span
                className={classNames(className, {
                    'font-bold': bold,
                    'font-italic': italic,
                    'font-mono bg-neutral-400 py-1 px-2 rounded-sm text-red-500':
                        code,
                    'line-through': strikethrough,
                    underline: underline,
                })}
                style={
                    color !== 'default' &&
                    !isBackgroundColor
                        ? { color, whiteSpace: 'pre-line' }
                        : {
                              backgroundColor:
                                  backgroundColorName,
                              whiteSpace: 'pre-line',
                          }
                }
                key={index}
            >
                {text?.link ? (
                    <a
                        href={text?.link?.url}
                        className='link'
                    >
                        {text.content}
                    </a>
                ) : (
                    text?.content || ''
                )}
            </span>
        );
    });
};

export default RichText;
