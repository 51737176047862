import '../Styles/Heading.css';

const Heading = ({ index, heading }) => {
    return (
        <div className='heading'>
            <span className='heading-index'>{index}</span>
            {heading.split(' ').map((hp, i) => (
                <span
                    key={i}
                    className='heading-part'
                    style={{
                        color:
                            i % 2
                                ? 'var(--clr-primary)'
                                : 'var(--clr-light)',
                    }}
                >
                    {hp}
                </span>
            ))}
        </div>
    );
};

export default Heading;
