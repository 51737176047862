import Icon from './Icon';

import '../Styles/Pill.css';

const Pill = ({
    text,
    icon,
    iconType = 'fontawesome',
    link,
    ...extra
}) =>
    link ? (
        <a
            href={link}
            target='_blank'
            rel='noreferrer'
            className='pill'
            {...extra}
        >
            {icon && (
                <Icon
                    icon={icon}
                    iconType={iconType}
                    className='project-icon'
                />
            )}
            <span className='pill-text'>{text}</span>
        </a>
    ) : (
        <div className='pill' {...extra}>
            {icon && (
                <Icon
                    icon={icon}
                    iconType={iconType}
                    className='project-icon'
                />
            )}
            <span className='pill-text'>{text}</span>
        </div>
    );

export default Pill;
