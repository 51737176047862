import Django from '../../assets/stack/django';
import Flask from '../../assets/stack/flask';
import Flutter from '../../assets/stack/flutter';
import Node from '../../assets/stack/node';
import Python from '../../assets/stack/python';
import React from '../../assets/stack/react';
import Scripting from '../../assets/stack/scripting';

import projectsData from './projects.json';
import experiencesData from './experiences.json';
import socialsData from './socials.json';

export const skills = [
    {
        name: 'ReactJS',
        icon: 'react',
        iconSrc: React,
    },
    {
        name: 'NodeJS',
        icon: 'node',
        iconSrc: Node,
    },
    {
        name: 'Python',
        icon: 'python',
        iconSrc: Python,
    },
    {
        name: 'Django',
        icon: 'django',
        iconSrc: Django,
    },
    {
        name: 'Flask',
        icon: 'flask',
        iconSrc: Flask,
    },
    {
        name: 'Flutter',
        icon: 'flutter',
        iconSrc: Flutter,
    },
    {
        name: 'Shell Scripting',
        icon: 'scripting',
        iconSrc: Scripting,
    },
];

export const projects = [...projectsData];

export const experiences = [...experiencesData];

export const socials = [...socialsData];

// eslint-disable-next-line
export default {
    skills,
    projects,
    experiences,
    socials,
};
