import '../Styles/Footer.css';

const Footer = () => {
    return (
        <div id='footer'>
            <h4 className='footer-text'>
                Made with ❤ by{' '}
                <span className='footer-accent footer-name'>
                    Ishit Beswal
                </span>
            </h4>
            {/* <h4 className='footer-text'>
                Github Profile Visits :{' '}
                <span className='footer-accent'>24</span>
            </h4> */}
        </div>
    );
};

export default Footer;
