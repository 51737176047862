import '../Styles/Loader.css';

const Loader = () => (
    <div className='preloader'>
        <div className='preloader__box'>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
);

export default Loader;
