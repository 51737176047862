import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Heading from './Heading';
import Project from './Project';

import '../Styles/Projects.css';

const Projects = ({
    setShow,
    setProject,
    setCurrNav,
    projects,
}) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    useEffect(() => {
        if (inView) setCurrNav(3);

        // eslint-disable-next-line
    }, [inView]);

    return (
        <section id='projects' ref={ref}>
            <Heading
                index='03.'
                heading="Some things I've built"
            />
            <div className='projects'>
                {projects?.map((project, index) => (
                    <Project
                        key={index}
                        project={project}
                        setShow={setShow}
                        setProject={setProject}
                    />
                ))}
            </div>
        </section>
    );
};

export default Projects;
