import { useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';

import Heading from './Heading';

import '../Styles/Rebuild.css';
import '../Styles/ContactMe.css';

const toaster = (dark, message, error = false) => {
    !error
        ? toast.success(message, {
              style: {
                  border: '1px solid #61D245',
                  padding: '0.85rem',
                  color: '#61D245',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  backgroundColor:
                      'hsla(195, 6%, 6%, 0.95)',
                  boxShadow: `0 0 10px ${
                      dark
                          ? 'hsla(197, 24%, 94%, 0.7)'
                          : 'hsla(195, 6%, 6%, 0.95)'
                  }`,
              },
              iconTheme: {
                  secondary: 'hsl(197, 24%, 94%)',
              },
          })
        : toast.error(message, {
              style: {
                  border: '1px solid hsl(359, 77%, 56%)',
                  padding: '0.85rem',
                  color: 'hsl(359, 77%, 56%)',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  backgroundColor: dark
                      ? 'hsla(195, 6%, 6%, 0.85)'
                      : 'hsla(195, 100%, 100%, 0.85)',
                  boxShadow: `0 0 10px ${
                      dark
                          ? 'hsla(197, 24%, 94%, 0.7)'
                          : 'hsla(195, 6%, 6%, 0.95)'
                  }`,
              },
              iconTheme: {
                  primary: 'hsl(359, 77%, 56%)',
                  secondary: 'hsl(197, 24%, 94%)',
              },
          });
};

const Rebuild = () => {
    const ADMIN_PASSWORD = process.env.ADMIN_PASSWORD;
    const REBUILD_URL = process.env.REBUILD_URL;

    const [passwordVerified, setPasswordVerified] =
        useState(false);

    const passSubmit = (event) => {
        event.preventDefault();

        const passwordEntered =
            event?.currentTarget?.elements?.ADMIN_PASSWORD
                ?.value;
        if (passwordEntered === ADMIN_PASSWORD) {
            setPasswordVerified(true);
        } else {
            toaster(true, 'Incorrect Password!', true);
        }
    };

    const onRebuild = async () => {
        try {
            await fetch(REBUILD_URL, {
                method: 'POST',
            });
            toaster(
                true,
                'Website Rebuild Process Started! Check netlify for process.',
            );
        } catch (e) {
            toaster(
                true,
                e?.message ||
                    'Unable to rebuild website! Check Netlify Logs',
                true,
            );
        } finally {
            setPasswordVerified(false);
        }
    };

    return (
        <>
            <Toaster />
            <main className='rebuild-page'>
                <Heading heading='Rebuild Website' />
                {!passwordVerified ? (
                    <form
                        className='contact-form'
                        onSubmit={passSubmit}
                    >
                        <div className='contact-field'>
                            <label
                                htmlFor='password'
                                className='contact-label'
                            >
                                Enter Password:
                            </label>
                            <input
                                id='password'
                                type='password'
                                name='password'
                                placeholder='Password'
                                required
                                className='contact-input'
                            />
                        </div>
                        <button
                            className={'contact-btn btn'}
                            type='submit'
                        >
                            Submit{' '}
                            <span className='fas send-icon'>
                                {'\uf1d8'}
                            </span>
                        </button>
                    </form>
                ) : (
                    <button
                        className={'contact-btn btn'}
                        onClick={onRebuild}
                    >
                        REBUILD{' '}
                        <span className='fas send-icon'>
                            {'\uf1d8'}
                        </span>
                    </button>
                )}
            </main>
        </>
    );
};

export default Rebuild;
