import '../Styles/Project.css';

const Project = ({ project, setShow, setProject }) => {
    return (
        <div
            className='project'
            onClick={() => {
                setShow(true);
                setProject(project);
            }}
        >
            <div className='project-cover-container'>
                <img
                    className='project-cover'
                    src={project.cover}
                    alt={`${project.name} Cover`}
                />
            </div>
            <div className='project-description'>
                <h2 className='project-title'>
                    {project.name}
                </h2>
                <p className='project-slug'>
                    {project.slug}
                </p>
            </div>
        </div>
    );
};

export default Project;
