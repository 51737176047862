import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import TextLoop from 'react-text-loop';

import '../Styles/Hero.css';

const Hero = ({ setCurrNav }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    useEffect(() => {
        if (inView) setCurrNav(0);

        // eslint-disable-next-line
    }, [inView]);

    return (
        <section id='home' ref={ref}>
            <aside className='description'>
                <h3 className='greet'>
                    Hey there!{' '}
                    <span className='greet-anim'>👋</span>
                </h3>
                <h1 className='name'>
                    I'm{' '}
                    <span className='name-span'>
                        Ishit Beswal
                    </span>
                </h1>
                <h2 className='sub-line'>
                    I do web stuff.
                </h2>
                <div className='description-text'>
                    <span style={{ marginRight: '0.5rem' }}>
                        I'm a
                    </span>
                    <TextLoop
                        children={[
                            ' Fullstack Developer 💻',
                            ' Technology Enthusiast 💽',
                            ' Foodie 🍔',
                            ' Cinephile 🎞',
                        ]}
                    />
                </div>
            </aside>
            <aside className='hero-image'>
                <div className='hero-img-container'>
                    <img
                        src='https://ik.imagekit.io/devilish1906/portfolio/MeMoji-1'
                        alt='me'
                    />
                </div>
            </aside>

            <a href='#about' className='fas go-down'>
                {'\uf078'}
            </a>
            <a href='#about' className='fas go-down'>
                {'\uf078'}
            </a>
            <a href='#about' className='fas go-down'>
                {'\uf078'}
            </a>
        </section>
    );
};

export default Hero;
