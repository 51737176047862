import { Tooltip as ReactTooltip } from 'react-tooltip';

import useWindowDimnesions from '../hooks/useWindowDimensions';

import { socials } from '../assets/lib/content';
import ThemeIcon from '../assets/svgs/theme';

import '../Styles/Socials.css';

const Socials = ({ dark, setDark }) => {
    const { width } = useWindowDimnesions();

    let socialsFirstHalf = socials.slice(
        0,
        socials.length / 2,
    );
    let socialsSecondHalf = socials.slice(
        socials.length / 2,
        socials.length,
    );

    return (
        <div id='socials'>
            <div className='line'></div>
            {socialsFirstHalf.map((social) => (
                <a
                    key={social.name}
                    href={
                        social.type === 'link'
                            ? social.link
                            : `${social.type}:${social.link}`
                    }
                    target='_blank'
                    rel='noreferrer'
                    className='social'
                    name={social.name}
                    data-tip={social.name}
                >
                    <ReactTooltip
                        place={width > 900 ? 'left' : 'top'}
                        type='dark'
                        effect='solid'
                    />
                    <i className={social.icon} />
                </a>
            ))}
            <span
                className='social social-btn'
                onClick={() => setDark((curr) => !curr)}
                data-tip={`Switch to ${
                    dark ? 'light' : 'dark'
                } theme`}
                data-name='Theme Toggle'
                data-offset="{'top': 3}"
            >
                <ReactTooltip
                    place={width > 900 ? 'left' : 'top'}
                    type='dark'
                    effect='solid'
                    // offset={{
                    //     top: 5,
                    // }}
                />
                <ThemeIcon
                    dark={dark}
                    className='theme-toggle'
                />
            </span>
            {socialsSecondHalf.map((social) => (
                <a
                    key={social.name}
                    href={
                        social.type === 'link'
                            ? social.link
                            : `${social.type}:${social.link}`
                    }
                    target='_blank'
                    rel='noreferrer'
                    className='social'
                    data-tip={social.name}
                >
                    <ReactTooltip
                        place={width > 900 ? 'left' : 'top'}
                        type='dark'
                        effect='solid'
                    />
                    <i className={social.icon} />
                </a>
            ))}
        </div>
    );
};

export default Socials;
