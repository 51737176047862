import { createRoot } from 'react-dom/client';
import {
    Route,
    Routes,
    BrowserRouter,
} from 'react-router-dom';

import './index.css';
import App from './App';
import Rebuild from './Components/Rebuild';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/*' element={<App />} />
            <Route path='/rebuild' element={<Rebuild />} />
        </Routes>
    </BrowserRouter>,
);
