import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import '../Styles/About.css';

import Heading from './Heading';
import RichText from './RichText';

const About = ({ setCurrNav, data }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    useEffect(() => {
        if (inView) setCurrNav(1);

        // eslint-disable-next-line
    }, [inView]);

    return (
        <section id='about' ref={ref}>
            <Heading index='01.' heading='About Me' />
            <div className='about'>
                {data?.map((text, index) => (
                    <p className='about-p' key={index}>
                        <RichText text={text} />
                        <br />
                        <br />
                    </p>
                ))}
            </div>
        </section>
    );
};

export default About;
