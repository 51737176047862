const django = ({ ...rest }) => {
    return (
        <svg
            fill='#000000'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='384px'
            height='384px'
            {...rest}
        >
            <path d='M18 6h-1c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h1c.552 0 1 .448 1 1v1C19 5.552 18.552 6 18 6zM19 16.702c0 .952-.213 3.983-2.741 5.135-.25.114-.542.095-.792-.018l-1.541-.698c-.369-.167-.387-.667-.046-.884 1.46-.927 2.12-2.253 2.12-3.535V9c0-.552.448-1 1-1h1c.552 0 1 .448 1 1V16.702zM11 4v4.159C10.653 8.018 9.904 8 9.535 8 6.759 8 4.19 9.306 4.19 13c0 4.42 3.48 5 5.81 5 .703 0 2.085-.053 3.143-.207C13.636 17.721 14 17.3 14 16.802V4c0-.552-.448-1-1-1h-1C11.448 3 11 3.448 11 4zM9.97 15.533c-1.31 0-2.58-.413-2.58-2.533s1.27-2.45 2.58-2.45c.32 0 .68.06 1.03.14v4.703C10.65 15.473 10.29 15.533 9.97 15.533z' />
        </svg>
    );
};

export default django;
