import { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useWindowDimensions from '../hooks/useWindowDimensions';

import Heading from './Heading';

import '../Styles/Experiences.css';

const Experiences = ({ setCurrNav, experiences }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    useEffect(() => {
        if (inView) setCurrNav(4);

        // eslint-disable-next-line
    }, [inView]);

    const experiencesRef = useRef();

    const [currExp, setCurrExp] = useState(0);
    const { width } = useWindowDimensions();

    useEffect(() => {
        experiencesRef.current.scroll(
            currExp * experiencesRef.current.clientWidth,
            0,
        );
    }, [currExp]);

    return (
        <section id='experiences' ref={ref}>
            <Heading index='04.' heading='Experiences' />
            <div className='experiences'>
                <ul
                    className='e-nav'
                    style={{
                        '--tab-width': `${
                            100 / experiences?.length
                        }%`,
                    }}
                >
                    {experiences?.map((experience, i) => (
                        <li
                            className={`e-nav-item`}
                            key={i}
                        >
                            <button
                                className={`e-nav-button ${
                                    i === currExp
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() =>
                                    setCurrExp(i)
                                }
                            >
                                {experience.company}
                            </button>
                        </li>
                    ))}
                    <li
                        className='track'
                        style={{
                            display: 'block',
                            transform:
                                width > 1000
                                    ? `translate(-50%, ${
                                          currExp * 100
                                      }%)`
                                    : `translate(${
                                          currExp * 100
                                      }%, -50%)`,
                        }}
                    ></li>
                </ul>
                <div
                    className='experiences-container'
                    ref={experiencesRef}
                >
                    {experiences?.map(
                        (experience, index) => (
                            <div
                                className={`experience ${
                                    index === currExp
                                        ? 'active'
                                        : ''
                                }`}
                                key={index}
                            >
                                <div className='e-heading'>
                                    {experience.position}{' '}
                                    <h3 className='e-heading-part'>
                                        @
                                        {experience.company}
                                    </h3>
                                </div>
                                <p className='e-duration'>
                                    {experience.start} -{' '}
                                    {experience.end ||
                                        'Present'}
                                </p>
                                <ol className='e-points'>
                                    {experience?.points?.map(
                                        (point, i) => (
                                            <li
                                                className='e-point'
                                                key={i}
                                            >
                                                {point}
                                            </li>
                                        ),
                                    )}
                                </ol>
                            </div>
                        ),
                    )}
                </div>
            </div>
        </section>
    );
};

export default Experiences;
