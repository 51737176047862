import '../Styles/Navbar.css';

const Navbar = ({ currNav, setCurrNav }) => {
    const navItems = [
        {
            title: 'Home',
            link: '#home',
        },
        {
            title: 'About',
            link: '#about',
        },
        {
            title: 'Skills',
            link: '#skills',
        },
        {
            title: 'Projects',
            link: '#projects',
        },
        {
            title: 'Experiences',
            link: '#experiences',
        },
        {
            title: 'Contact Me',
            link: '#contact-me',
        },
    ];

    return (
        <nav id='navbar'>
            <a href='#home' className='nav-logo'>
                IB
            </a>
            {navItems.map((item, index) => (
                <a
                    href={item.link}
                    className={`nav-item ${
                        currNav === index ? 'active' : ''
                    } ${
                        currNav > index ? 'next-active' : ''
                    }`}
                    key={index}
                    onClick={() => {
                        setCurrNav(index);
                    }}
                    style={{
                        animation: `${item.title
                            .toLowerCase()
                            .replace(' ', '-')}-start ${
                            (index + 1) * 150
                        }ms forwards`,
                    }}
                >
                    <span className='nav-link'>
                        {index > 0 && (
                            <span className='nav-index'>
                                0{index}.
                            </span>
                        )}{' '}
                        {item.title}
                    </span>
                </a>
            ))}

            <a
                className='nav-btn btn'
                href='/resume.pdf'
                target='_blank'
                rel='noreferrer'
            >
                Resume
            </a>
        </nav>
    );
};

export default Navbar;
