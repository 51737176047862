import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useInView } from 'react-intersection-observer';

import Heading from './Heading';

import '../Styles/ContactMe.css';

const toaster = (dark, message, error = false) => {
    !error
        ? toast.success(message, {
              style: {
                  border: '1px solid #61D245',
                  padding: '0.85rem',
                  color: '#61D245',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  backgroundColor:
                      'hsla(195, 6%, 6%, 0.95)',
                  boxShadow: `0 0 10px ${
                      dark
                          ? 'hsla(197, 24%, 94%, 0.7)'
                          : 'hsla(195, 6%, 6%, 0.95)'
                  }`,
              },
              iconTheme: {
                  secondary: 'hsl(197, 24%, 94%)',
              },
          })
        : toast.error(message, {
              style: {
                  border: '1px solid hsl(359, 77%, 56%)',
                  padding: '0.85rem',
                  color: 'hsl(359, 77%, 56%)',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  backgroundColor: dark
                      ? 'hsla(195, 6%, 6%, 0.85)'
                      : 'hsla(195, 100%, 100%, 0.85)',
                  boxShadow: `0 0 10px ${
                      dark
                          ? 'hsla(197, 24%, 94%, 0.7)'
                          : 'hsla(195, 6%, 6%, 0.95)'
                  }`,
              },
              iconTheme: {
                  primary: 'hsl(359, 77%, 56%)',
                  secondary: 'hsl(197, 24%, 94%)',
              },
          });
};

const ContactMe = ({ dark, setCurrNav }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    useEffect(() => {
        if (inView) setCurrNav(5);

        // eslint-disable-next-line
    }, [inView]);

    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    const [loading, setLoading] = useState(false);

    const clearInputs = () => {
        nameRef.current.value = '';
        emailRef.current.value = '';
        messageRef.current.value = '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const message = messageRef.current.value;

        const apiUrl = `${
            process.env.REACT_APP_API
                ? process.env.REACT_APP_API
                : ''
        }/api/contact`;

        setLoading(true);

        axios({
            url: apiUrl,
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            data: { name, email, message },
        })
            .then(async (resp) => {
                toaster(dark, resp.data.msg);
                clearInputs();
            })
            .catch((e) => {
                console.log(e);
                toaster(
                    dark,
                    'Please provide a proper message.',
                    true,
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <section id='contact-me' ref={ref}>
            <Heading index='05.' heading='Contact Me' />
            <form
                className='contact-form'
                onSubmit={handleSubmit}
            >
                <div className='contact-field'>
                    <label
                        htmlFor='contact-name'
                        className='contact-label'
                    >
                        Your Name:
                    </label>
                    <input
                        id='contact-name'
                        type='text'
                        name='name'
                        ref={nameRef}
                        placeholder='Name'
                        required
                        className='contact-input'
                    />
                </div>
                <div className='contact-field'>
                    <label
                        htmlFor='contact-email'
                        className='contact-label'
                    >
                        Your Email:
                    </label>
                    <input
                        id='contact-email'
                        type='email'
                        name='email'
                        ref={emailRef}
                        placeholder='Email'
                        required
                        className='contact-input'
                    />
                </div>
                <div className='contact-field'>
                    <label
                        htmlFor='contact-message'
                        className='contact-label'
                    >
                        Message:
                    </label>
                    <textarea
                        id='contact-message'
                        name='message'
                        ref={messageRef}
                        placeholder='Message'
                        required
                        className='contact-input contact-textarea'
                        rows='7'
                    ></textarea>
                </div>
                <button
                    className={`contact-btn btn ${
                        loading ? 'loading' : ''
                    }`}
                    type='submit'
                >
                    {loading ? (
                        <>
                            <span className='loading-icon fas'>
                                {'\uf110'}
                            </span>
                        </>
                    ) : (
                        <>
                            Send{' '}
                            <span className='fas send-icon'>
                                {'\uf1d8'}
                            </span>
                        </>
                    )}
                </button>
            </form>
        </section>
    );
};

export default ContactMe;
