const theme = ({ dark, ...rest }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 472.39 472.39'
            {...rest}
        >
            <g
                className='toggle-sun'
                style={{
                    transition:
                        'fill 0s, transform 750ms cubic-bezier(0.11, 0.14, 0.29, 1.32)',
                }}
            >
                <path
                    fill='var(--clr-light)'
                    d='M403.21,167V69.18H305.38L236.2,0,167,69.18H69.18V167L0,236.2l69.18,69.18v97.83H167l69.18,69.18,69.18-69.18h97.83V305.38l69.18-69.18Zm-167,198.17a129,129,0,1,1,129-129A129,129,0,0,1,236.2,365.19Z'
                />
            </g>
            <g
                className='toggle-circle'
                style={{
                    transition: 'fill 0s, transform 750ms',
                    transform: dark
                        ? 'translateX(0)'
                        : 'translate(-15%)',
                }}
            >
                <circle
                    fill='var(--clr-light)'
                    className='cls-1'
                    cx='236.2'
                    cy='236.2'
                    r='103.78'
                    rotate={dark ? '0' : '900deg'}
                />
            </g>
        </svg>
    );
};

export default theme;
